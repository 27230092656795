<template>
  <div>
    <b-container class="no-gutters bg-white px-2 px-md-3 pb-2 pb-md-3 pt-3">
      <div class="text-right my-2">
        <b-button variant="custom-primary" class="" @click="exportFile"
          >Export</b-button
        >
      </div>
      <b-table
        ref="tableCustomer"
        responsive
        striped
        hover
        :fields="fields"
        :items="items"
        :busy="isBusy"
        show-empty
        empty-text="No matching records found"
      >
        <template v-slot:cell(member_id)="data">
          <div class="justify-content-center text-left">
            <router-link :to="'/report/customer/' + data.item.user_guid">
              <u> {{ data.item.member_id }}</u>
            </router-link>
          </div>
        </template>
        <template v-slot:cell(telephone)="data">
          <div>{{ convertTelePhone(data.item.telephone) }}</div>
        </template>
        <template v-slot:table-busy>
          <div class="text-center text-black my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-2">Loading...</strong>
          </div>
        </template>
      </b-table>
      <Pagination
        @handleChangeTake="handleChangeTake"
        :pageOptions="pageOptions"
        :filter="filter"
        :rows="rows"
        @pagination="pagination"
      />
    </b-container>
  </div>
</template>

<script>
export default {
  props: {
    fields: {
      required: true,
      type: Array,
    },
    items: {
      required: true,
      type: Array,
    },
    isBusy: {
      required: true,
      type: Boolean,
    },
    rows: {
      required: true,
      type: Number,
    },
    filter: {
      required: true,
      type: Object,
    },
    pageOptions: {
      required: true,
      type: Array,
    },
    showing: {
      required: true,
      type: Number,
    },
    showingTo: {
      required: true,
      type: Number,
    },
    form: {
      required: false,
    },
  },
  data() {
    return {
      toShow: this.showingTo,
      show: this.showing,
    };
  },
  methods: {
    handleChangeTake(value) {
      this.filter.page = 1;
      this.show = 1;
      this.filter.take = value;
      this.toShow = value;
      this.filter.redeem_date = null;
      this.filter.used_date = null;
      this.$emit("getPageOption", this.filter);
    },
    pagination(value) {
      this.filter.page = value;
      if (value === 1) {
        this.show = this.filter.page;
        this.toShow = this.filter.take;
      } else {
        this.show = value * this.filter.take - this.filter.take;
        this.toShow = this.show + this.filter.take;
      }
      this.$emit("getPage", this.filter);
    },
    async exportFile() {
      this.$bus.$emit("showLoading");
      let data = await this.axios(
        `/customer/segment/export_customer_list/${this.$route.params.id}`,
        {
          responseType: "blob",
        }
      );

      var fileURL = window.URL.createObjectURL(new Blob([data.data]));
      var fileLink = document.createElement("a");
      // this.$bus.$emit("showLoading");
      // var dateExcel = this.$moment().format("DDMMYYYYhhmmss");
      fileLink.href = fileURL;
      fileLink.setAttribute(
        "download",
        `segment-${this.form.name}-customer.xlsx`
      );
      document.body.appendChild(fileLink);
      fileLink.click();
      this.$bus.$emit("hideLoading");
    },
  },
};
</script>

<style lang="scss" scoped>
.time-color {
  color: #b5b5b5;
}
.status-complete {
  color: #00bd00;
}
.status-void {
  color: #f9b115;
}
.status-cancel {
  color: #ff0000;
}
</style>
